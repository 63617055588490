import React from 'react';
import AOS from "aos";
import {Container, Row, Col} from 'react-bootstrap'
// import Logo from '../../assets/images/master-logo.png'

const Banner = () => {
    React.useEffect(() => {
        AOS.init({
            duration : 1000
          });
    }, []);
    return (
        <div className='home-banner'>
            <Container>
                <Row className='align-items-center'>
                    <Col md={10}>
                        <h1 className='mb-md-5 mb-4' data-aos="fade-up">Empowering the Decentralized Ecosystem</h1>
                    </Col>
                    <Col md={8}>
                        <p className='lead' data-aos="fade-up" data-aos-delay="100">MasterMind has invested in cryto assets and blockchain companies, providing investors with the full spectrum of exposure to the space.</p>
                    </Col>
                    {/* <Col md={4}>
                        <img src={Logo} alt="logo" className='img-fluid' />
                    </Col> */}
                </Row>
            </Container>
        </div>
    );
};

export default Banner;